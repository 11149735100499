body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-size: smaller !important;
}
/* body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

/*  css */
/* css button ajouter une note */
#affixNote {
  top: 80px;
  position: relative;
  left: 1230px;
  width: 222px;
  z-index: 1000;
  border-radius: 15px;
  margin:7px;
  /* margin-left: -380px; */
  /* margin-left: 584px */
}

textarea#ficheCmpagnieText {
  width: 100%;
  padding-top: 13px;
  padding-inline: 17px;
  padding-bottom: -45px;
  height: 120px;
  border: none;
  font-family: 'FontAwesome';
  font-size: larger;
}
.modal-body {
font-family: 'FontAwesome';
font-size: larger;
color: black;
}
.modalHeader{
  color: #20a8d8;
}
.modalficheCompagnie{
display: flex;

}

/* .logopdf2 {
  border-left: 1px solid gray;
} */

.textFicheCompagnie{
  flex: 5;
  padding-right: 20px;
}

.imgCompagnie{
    /* margin-left: 9px; */
    display: flex;
    align-content: center;
}
.LogoPdfImg {
  height: auto;
  border-left: solid 1px gray;
  display: grid;
  justify-content: start;
  justify-items: start;
  padding-left: 11px;
}
/* .LogoImg {
  height: auto;
  border-left: solid 1px gray;
  display: grid;
  justify-content: start;
  justify-items: start;
  padding-left: 11px;

} */

textarea.updateFicheText {
  width: 100%;
  height: 309px;
  border: none;
}
/* //  correction modal css */
.modalBodyFicheCompagnie{
  /* height: 330px; */
  height: auto;
}


span.Televerser {
  color: #20a8d8;
  cursor: pointer;
  margin-left: 17px;
}
.Telecharger{
  width: 100px;
  height: 36px ;
  text-align: center;
  display: flex ;
  justify-content: center ;
  flex-direction: column ;
  font-family: sans-serif;
  margin-left: 5px;
  color: #20a8d8 ;
  font-size: 13px;
}
.nameFileupload{
  width: 100px;
  height: 36px ;
  text-align: center;
  display: flex ;
  justify-content: center ;
  flex-direction: column ;
  font-family: sans-serif;
    font-size: 13px;
    color: #20a8d8 ;
}
/* .telechargement{
  font-size: 16px !important;
} */
.parrainage{
  color: gray;
}

.iconFicheName{
  display: flex;
  justify-content: space-between;
}
.pdfImage{
flex:1;
}
.logopdf.logopdf2 {
  border-left: 1px gray solid;
}
  

/* ///  css script APPEL */

.labelUpdate {
  display: flex;
  justify-content: flex-start
 
}
.listingApproche {
  margin-left: 80px;
}

.listingSecteurActivite {
  display: flex;
  margin-left: 35px;
}
label.labelContenu {
  display: flex;
 
}

.ant-col.ant-col-24 {
  flex-wrap: wrap !important;
}
/*  erreur police CRM */
td {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
th {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

/* // Desactiver copier coller via css  */

 .componentAffectationContact:nth-of-type(1){
   user-select:none;
 }

 /* styles.css (ou tout autre nom de fichier CSS que vous utilisez dans votre projet) */

.calendar-container {
  width: 100%;
  max-width: 800px; /* Ajustez selon vos besoins */
  margin: 0 auto; /* Centre l'iFrame horizontalement */
  padding: 20px; /* Espacement autour du calendrier */
  border: 1px solid #ccc; /* Bordure grise */
  border-radius: 5px; /* Coins arrondis */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Ombre légère */
}

.calendar-container iframe {
  width: 100%;
  height: 600px; /* Hauteur du calendrier */
  border: none; /* Pas de bordure sur l'iFrame */
}

#SearchIconTvDisplay4{
  color:black;
  margin-Right:15px;
  cursor:pointer;
}
.tablesTvDisplay4{
  display: flex;
  align-items: center;
 justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
}
.input-placeholder:focus{
  border: none !important;
}

.ant-tabs-tab {
  height: 35px;
}

.custom-tooltip {
  background-color: #5585b5 !important;
  color: white !important;
  font-size: 14px !important;
 
  
}

.custom-arrow {
  color: #5585b5 !important;
}

/* CSS TO JUSTIFY HOME PAGE CONTENT AFTER UPGRADE NODE */
.container {
  background: #ffffff;
}


/* ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;; */
.custum-navbar{
  height:60px;
  border-bottom: solid 1px rgb(206, 206, 206);
  background: #e60c0c !important;
}

/* Styles pour les barres de défilement */

/* Pour Chrome, Safari et Edge */
.scrollable-content::-webkit-scrollbar {
  height: 4px; /* Hauteur de la barre de défilement horizontale */
  width: 7px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #888; /* Couleur de la barre */
  border-radius: 10px; /* Arrondi de la barre */
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Couleur au survol */
}

 /* css navbar */
/* .container-fluid {
  background-color: #fff;
  border-bottom: 1px solid #c8ced3;

} */

ul.nav.justify-content-around.d-flex.flex-wrap.align-items-center.m-0.p-0 {
  background: white;
  font-size: 14px;
  height: 60px;
  /* border-bottom: darkgray 1px solid; */
padding-left: 10px !important;

}
.col-md-1.g-0.p-0.m-0 {
  background: rgb(255, 255, 255);
  height: 60px;
  /* border-bottom: darkgray 1px solid; */
}

/* ul.nav.justify-content-between.d-flex.flex-wrap.align-items-center.m-0.p-0 {
  position: fixed;
  z-index: 1002;
} */

ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root.css-8ge7h6-MuiList-root-MuiMultiSectionDigitalClockSection-root {
  COLOR: CHOCOLATE;
}
.active_link{
text-decoration: none;
color: #f96d00;
}
.inactive_link{
  text-decoration: none;
  color: inherit;
}
/* Largeur normale de la barre latérale */
/* .sidebar {
  width: 250px;
  transition: width 0.3s ease;
} */

/* Mode "narrow" appliqué pour les petits écrans (768px et moins) */
 @media (max-width: 992px) {
  .sidebar {
    width: 60px !important;
    overflow: hidden !important;
    display: none !important;
  }
}

.text_decoration{
  text-decoration: none !important;
  color: #3c79f5 !important;
}

/* CSS AGENDA D'INSTALLATION */

.rbc-toolbar {
  background: rgb(213, 104, 3);
  background:#5e63b6;
  color: #fff;             
  border: none;             
  padding: 10px 20px;      
  /* border-radius: 5px;        */
  cursor: pointer;  
}

.custom-button {
  background: rgb(255, 252, 252) !important; 
  color: rgb(0, 0, 0) !important;
  width: 120px !important;
  height: 35px !important;
  margin-left: 8px !important;
  /* font-weight: bolder; */
  border-radius: 20px !important;
}

.custom-button:hover {
  background: rgb(240, 202, 164) !important; 
  color: white !important; 
}


.custom-button2 {
  background: rgb(255, 252, 252) !important; 
  color: rgb(0, 0, 0) !important;
  width: 30px !important;
  height: 30px !important;
  /* margin-left: 8px !important; */
  /* font-weight: bolder; */
  border-radius:50% !important;
}

.custom-button2:hover {
  background: rgb(240, 202, 164) !important; 
  color: white !important; 
}

/* Mode clair */
.light-mode {
  background-color: white;
  color: black;
}

/* Mode sombre */
.dark-mode {
  background-color: #1b1919;
  background-color: #353434;
  color: white;
}

/* Exemple de style pour le calendrier */
.react-big-calendar {
  background-color: rgb(212, 212, 212);
  color: inherit;
}

